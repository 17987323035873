$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
section.auth {
    max-width: 1200px;

    * {
        border-radius: 0 !important;
    }

    .auth-content {
        margin-top: 100px;
    }

    //.logo-text {
    //    color: $cps-primary-color-dark !important;
    //    margin: 60px 0px 20px;
    //    font-size: 42px;
    //}

    //.login-logo {
    //    margin: 70px 0 20px;
    //    max-width: 235px;
    //}

    .btn-primary {
        width: 100%;
        min-width: 150px;
        height: 60px;
        color: #fff;
        padding: 4px 12px !important;
        background-color: $cps-primary-color-dark !important;
        border-color: $cps-primary-color-dark !important;
    }

    .btn-primary:hover {
        color: #fff;
        background-color: $cps-primary-color-dark !important;
        border-color: $cps-primary-color-dark !important;
    }

    .panel {
        margin-left: 80px;
        margin-right: 80px;
        margin-top: 21px;
        border-top: 0;
    }

    .panel-body {
        text-align: center;
        border-radius: 2px !important;
        padding: 5px;
    }

    .alert {
        padding: 10px 15px;
    }

    input {
        box-shadow: none !important;
        outline: none !important;
        -webkit-box-shadow: none !important;
    }

    .icon {
        width: 75px;
        height: 75px;
        margin: 5px auto 25px auto;
        border: 1px solid #ccc;
        border-radius: 50% !important;
        background-color: #efefef;
        text-align: center;
    }

    .icon * {
        margin-top: 27px;
        font-size: 1.3em;
        color: #555;
    }

    .btn {
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
    }
}
