$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
section.additional-organizer-information {
    label {
        font-size: 13px;
        display: inherit;
    }

    .form-group {
        margin-bottom: 5px;
    }

    .nav-tabs li > a {
        padding: 5px 15px;
    }

    .nav-tabs {
        margin-bottom: 10px;
    }
}

section.dashboard-organizer-quote {
    // colors need to be marked important within a print media query in order to be displayed in phantomJS

    @media print {
        .bb {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
        }

        // this should cover any text within the panel
        .panel-body {
            p,
            li,
            label,
            td,
            th,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            div,
            span,
            big,
            strong,
            em,
            s,
            small,
            tt,
            code,
            kbd,
            samp,
            del,
            ins,
            cite,
            q,
            pre,
            br {
                color: #656565 !important;
            }
        }

        .docusign p {
            color: #ffffff !important;
            margin-bottom: 0;
        }

        .docusign hr {
            border-top-color: #656565 !important;
            margin-top: 5px;
        }
    }
}

.cps section.dashboard-organizer-quote {
    @media print {
        // panel header background
        .panel-heading {
            -webkit-print-color-adjust: exact;
            background-color: $cps-primary-color !important;
            color: #ffffff !important;
            border-radius: 0;
        }

        // panel title color
        .panel-heading {
            span,
            h2,
            h3,
            h4 {
                color: #ffffff !important;
            }
        }

        .panel-heading.heading-inverse {
            background-color: #ffffff !important;
            color: $cps-primary-color !important;
        }

        .panel-heading.heading-inverse {
            span,
            h2,
            h3,
            h4 {
                color: $cps-primary-color !important;
            }
        }
    }
}

pubhub section.dashboard-organizer-quote {
    @media print {
        // panel header background
        .panel-heading {
            -webkit-print-color-adjust: exact;
            background-color: $pubhub-primary-color !important;
            color: #ffffff !important;
            border-radius: 0;
        }

        // panel title color
        .panel-heading {
            span,
            h2,
            h3,
            h4 {
                color: #ffffff !important;
            }
        }

        .panel-heading.heading-inverse {
            background-color: #ffffff !important;
            color: $pubhub-primary-color !important;
        }

        .panel-heading.heading-inverse {
            span,
            h2,
            h3,
            h4 {
                color: $pubhub-primary-color !important;
            }
        }
    }
}
