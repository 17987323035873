$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
* {
    transition: 0s none;
    -webkit-transition: 0s none;
    animation: 0s none;
    -webkit-animation: 0s none;
}

.topnavbar .navbar-nav > li > a:hover,
.topnavbar .navbar-nav > .open > a:hover,
.topnavbar .navbar-nav > li > a:focus,
.topnavbar .navbar-nav > .open > a:focus {
    color: #fff9c2;
}

@media only screen and (max-width: 768px) {
    .topnavbar .navbar-right > li > a,
    .topnavbar .navbar-nav > .open > a {
        color: #457c8e;
    }
    .topnavbar .navbar-nav > li > a:hover,
    .topnavbar .navbar-nav > .open > a:hover,
    .topnavbar .navbar-nav > li > a:focus,
    .topnavbar .navbar-nav > .open > a:focus {
        color: #457c8e;
    }
}

@media only screen and (min-width: 768px) {
    .topnavbar .navbar-right > li > a,
    .topnavbar .navbar-nav > .open > a {
        color: #fff;
    }
    .topnavbar .navbar-right > li > a:hover,
    .topnavbar .navbar-nav > .open > a:hover {
        color: #fff9c2;
    }
}

.sidebar > .nav > li.active,
.sidebar > .nav > li.open,
.sidebar > .nav > li.active > a,
.sidebar > .nav > li.open > a,
.sidebar > .nav > li.active .nav,
.sidebar > .nav > li.open .nav {
    background-color: #f4f7fa;
    color: #457c8e;
}
.sidebar > .nav > li.active > a > em,
.sidebar > .nav > li.open > a > em {
    color: #457c8e;
}
.sidebar > .nav > li.active {
    border-left-color: #457c8e;
}
.sidebar > .nav > li > a:focus,
.sidebar > .nav > li > .nav-item:focus,
.sidebar > .nav > li > a:hover,
.sidebar > .nav > li > .nav-item:hover {
    color: #457c8e;
}
.offsidebar-icon {
    color: #bfc2c2;
}
.offsidebar-icon:hover {
    color: #457c8e;
}
.active .offsidebar-icon {
    color: #457c8e;
}
a {
    color: #457c8e;
    text-decoration: none;
}
tab-heading > em {
    color: #457c8e;
}
.switch input:checked + span {
    background-color: #457c8e; /* check */
    border-color: #fff;
    transition: all 0.5s;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: #707070;
}
.icon-refresh {
    color: #909fa7;
}
.status-icon {
    font-size: 10px !important;
}
.bg-inverse {
    background-color: #457c8e !important;
}

/* Input Validation */
input[role="validate"].ng-invalid.ng-dirty {
    background-color: #ffefeb !important;
    border-color: #ffa6a6;
}
input[role="validate"].ng-valid.ng-dirty {
    background-color: #fcfffc !important;
    border-color: #a2d8ae;
}
textarea[role="validate"].ng-invalid.ng-dirty {
    background-color: #ffefeb !important;
    border-color: #ffa6a6;
}
textarea[role="validate"].ng-valid.ng-dirty {
    background-color: #fcfffc !important;
    border-color: #a2d8ae;
}
select[role="validate"].ng-invalid.ng-dirty {
    background-color: #ffefeb !important;
    border-color: #ffa6a6;
}
select[role="validate"].ng-valid.ng-dirty {
    background-color: #fcfffc !important;
    border-color: #a2d8ae;
}

/* ngTags */
.tags {
    border: 1px solid #dde6e9 !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.tag-item {
    border: 1px solid #dde6e9 !important;
    background-color: #fff !important;
    color: #454545 !important;
}

.tag-item .remove-button {
    color: #2f6779 !important;
}

/* Context Menu */
.context-menu-active {
    background-color: #f4f5f6;
}
.context-menu-list {
    border: 1px solid #cfdbe2;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 14px;
}
.context-menu-item {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #5b5b5b;
}
.context-menu-item.context-menu-hover {
    //background-color: #005aa5;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.checkbox label,
.checkbox label * {
    color: #3a3f51 !important;
}

.drag-drop-zone {
    text-align: center;
    padding: 20px 0;
    border: 2px dashed #e7f1ff;
    cursor: pointer;
}

.drag-drop-zone-active {
    border: 2px dashed #005aa5;
}

.drop-zone-filled {
    padding: 20px 0;
}

.drop-zone-icon {
    color: #ff931d;
}

.drop-zone-icon:hover {
    color: #ff931d;
}

/** TinyMCE **/
.mce-panel * {
    //background-color: #f8f8f8 !important;
}

.mce-panel {
    background-color: #f8f8f8 !important;
    border-color: #d1d1d1 !important;
}

.mce-panel button,
.mce-panel .mce-btn {
    background-color: #f8f8f8 !important;
    //border-color: #d1d1d1 !important;
}

.mce-selected {
    background-color: #aaa !important;
}

//.mce-btn button {
//    color: #333 !important;
//}

.mce-primary button,
.mce-primary button i {
    color: #333 !important;
}

.mce-primary:hover button {
    border: 1px solid #ccc !important;
}
