$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
* {
    font-family: "Roboto", sans-serif;
}
.cps-logo {
    height: 35px;
    margin-left: 10px;
    margin-top: -7px !important;
}
.pubhub-logo {
    height: 35px;
    margin-left: 10px;
    margin-top: -7px !important;
}
.navbar {
    border: none !important;
    border-bottom: 1px solid #ddd !important;
}
.navbar-brand {
    color: #fff !important;
    padding: 17px !important;
}
.navbar-brand:hover {
    color: #fff !important;
    padding: 17px !important;
}
.topnavbar .navbar-header {
    width: auto !important;
}
@media only screen and (max-width: 768px) {
    .nav-wrapper {
        background-color: #fff;
    }
    .nav.navbar-nav {
        margin: 0 !important;
    }
}
.nav-tabs > li > a {
    padding: 7px 55px;
}
@media only screen and (min-width: 768px) {
    .content-heading {
        padding: 15px !important;
        //margin-bottom: 0 !important;
    }
    .topnavbar .navbar-nav > .open > a,
    .topnavbar .navbar-nav > .open > a:hover,
    .topnavbar .navbar-nav > .open > a:focus {
        box-shadow: 0 -3px 0 #fff inset !important;
        -webkit-transition: all 0.1s;
        -o-transition: all 0.1s;
        transition: all 0.1s;
    }
}

.tab-content {
    padding: 20px 5px;
}
.content-wrapper > h3 {
    font-size: 1.5em;
    max-height: 65px;
}
.table-hover > tbody > tr {
    cursor: pointer;
}
input {
    outline: none !important;
}
.ui-select-multiple.ui-select-bootstrap {
    padding: 5px 5px 2px 3px !important;
}
.ui-select-container * {
    opacity: 1 !important;
}
.ngdialog-lg .ngdialog-content {
    width: 550px !important;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #fbfbfb !important;
}
.modal-wide {
    width: 600px;
}
.modal-title {
    font-weight: normal !important;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: #707070;
}
.tab-content {
    border: 0;
}
.panel {
    border-radius: 4px !important;
}
.panel-heading a {
    text-align: right !important;
}
.panel.panel-default {
    border-top-width: 1px !important;
}
.icon-refresh {
    color: #909fa7;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Progress Bar */
.progress {
    border: 1px solid #fff !important;
    background-color: #dfdfe0 !important;
}
.progress-bar-muted {
    background-color: #b4bec1 !important;
}

/* Text Helpers */
.text-success {
    color: #22ab43;
}
.text-muted-light {
    color: #b4c4cc;
}

/* Panel */
.panel-heading {
    padding: 5px 10px;
}

.panel-primary > .panel-heading {
    background-color: $cps-primary-color !important;
}
.panel .table {
    margin-bottom: 7px !important;
}

@media screen and (max-width: 767px) {
    .table-responsive {
        border: 1px solid #fff !important;
    }
}

/* Buttons */
.btn-toolbar-config {
    margin: 0 0;
    padding: 7px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    background-color: #fff;
}
.btn-primary {
    color: $cps-primary-color-inverse;
    background-color: $cps-primary-color !important;
    border-color: $cps-primary-color !important;
}
.btn-primary:hover {
    color: $cps-primary-color-inverse;
    background-color: $cps-primary-color !important;
    border-color: $cps-primary-color !important;
}

/* Notifications */
.notifications {
    z-index: 100000000;
    opacity: 0.95;
    position: fixed;
    top: 40px;
    left: 35%;
}

.notifications .notification {
    border: 1px solid #cfdbe2;
    border-radius: 4px;
    padding: 15px 15px 15px 25px;
    margin-bottom: 15px;
    background-color: #fff;
    font-size: 1.25em;
}

.notification > .close {
    opacity: 0.5;
}

.notifications .danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}
.notifications .warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}
.notifications .success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

/* Notification animations */
.notification.fading.ng-move {
    opacity: 0;
    -webkit-transition: 0.25s linear all; /* Safari/Chrome */
    transition: 0.25s linear all; /* All other modern browsers and IE10+ */
}

.notification.fading.ng-move.ng-move-active {
    opacity: 1;
}

.notification.fading.ng-leave {
    opacity: 1;
    -webkit-transition: 0.25s linear all; /* Safari/Chrome */
    transition: 0.25s linear all; /* All other modern browsers and IE10+ */
}

.notification.fading.ng-leave.ng-leave-active {
    opacity: 0;
}

.notification:hover {
    opacity: 0.85;
    transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
}

.link-white {
    color: #fff !important;
}

.link-white:hover {
    color: #fff9c2 !important;
}

/* Date & Time Picker */
.uib-datepicker-popup .btn {
    border-color: #fff !important;
}
.uib-datepicker-popup .uib-button-bar {
    display: none !important;
}
.uib-datepicker-popup .btn:active,
.uib-datepicker-popup .btn.active {
    outline: 0;
    background-color: $cps-primary-color;
    color: $cps-primary-color-inverse;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.uib-datepicker-popup .btn-default:focus {
    background-color: #fff;
    border-color: #eaeaea;
}
.uib-datepicker-popup .btn-default:focus.active {
    background-color: #f5f5f5;
}

/* Tab Buttons */
uib-accordian {
    a {
        color: #454545 !important;
    }
}

/* Extra Padding Settings */
.pr-xxl {
    padding-right: 30px !important;
}
.mr-xxl {
    margin-right: 30px;
}

/* Extra Margin Settings */
.mt-n10 {
    margin-top: -10px;
}
.mt-n20 {
    margin-top: -20px;
}
/* Text */
.white-space-pre-line {
    white-space: pre-line;
}
