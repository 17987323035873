$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
@media only screen and (max-width: 767px) {
    .ak-preparation-modal-link {
        color: #fff !important;
        margin-top: 7px !important;
    }
}

#floating-button {
    display: inline-block;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #abcdef;
    border-color: #cfdbe2;
    position: fixed;
    bottom: 80px;
    left: 30px;
    cursor: pointer;
    box-shadow: 0px 2px 5px #bbb;
}

#floating-button-2 {
    width: 270px;
    height: 35px;
    position: fixed;
    bottom: 80px;
    left: 30px;
}

.text {
    color: white;
    position: absolute;
    top: 0;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0;
    margin: 0;
    line-height: 55px;
    font-size: 38px;
    font-family: "Roboto";
    font-weight: 300;
    animation: plus-out 0.3s;
    transition: all 0.3s;
}
