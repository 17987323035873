$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
section.table-of-contents-downloads {
    h4.panel-title {
        font-weight: normal !important;
    }
    button.btn.btn.active {
        background-color: white;
    }
    .icx-active-warning-tab {
        active: true;
        background-color: #f2dede;
        font-weight: bolder;
        border-color: #333333;
    }

    .tab-content {
        padding: 0px 0px !important;
    }
    .icx-inactive-warning-tab {
        background-color: #f2dede;
        font-weight: normal;
    }

    .icx-active-tab {
        active: true;
        background-color: white;
        font-weight: bolder;
        border-color: #333333;
    }

    .icx-inactive-tab {
        background-color: white;
        font-weight: normal;
    }

    .noshow-active-tab {
        active: true;
        background-color: white;
        font-weight: bolder;
        border-color: #333333;
    }
    .noshow-inactive-tab {
        background-color: white;
        font-weight: normal;
    }

    .missing-sub-active-warning-tab {
        active: true;
        background-color: #f2dede;
        font-weight: bolder;
        border-color: #333333;
    }

    .missing-sub-inactive-warning-tab {
        background-color: #f2dede;
        font-weight: normal;
    }

    .missing-sub-active-tab {
        active: true;
        background-color: white;
        font-weight: bolder;
        border-color: #333333;
    }

    .missing-sub-inactive-tab {
        background-color: white;
        font-weight: normal;
    }
}
