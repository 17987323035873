$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
.column-customization-modal {
    .column-item {
        padding: 8px 12px;
        margin: 4px 0;
        background: #f8f8f8;
        border: 1px solid #ddd;
        border-radius: 4px;
        transition: transform 0.15s ease;

        &:hover {
            background: #f0f0f0;
        }

        &.dndDragging {
            opacity: 0.7;
            transform: scale(1.02);
            background: #fff;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        }

        &.dndPlaceholder {
            background: #e8e8e8;
            border: 2px dashed #ccc;
            min-height: 42px;
        }

        .badge {
            margin-left: 8px;
            background-color: #5bc0de;
            font-weight: normal;
        }
    }

    .checkbox {
        margin: 0;
        position: relative;

        label {
            display: flex;
            align-items: center;
            padding: 0;
            margin: 0;
            min-height: 0;
            cursor: move;
            user-select: none;

            input[type="checkbox"] {
                position: relative;
                margin: 0 8px 0 0;
                cursor: pointer;
            }
        }

        .fa-check {
            display: none; // Hide the default check icon
        }

        .handle {
            color: #666;
            margin: 0 8px;
            cursor: move;

            &:hover {
                color: #333;
            }
        }
    }

    // Improve the drag target indicator
    .dndDragover {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: -2px;
            left: 0;
            right: 0;
            height: 2px;
            background: #2196f3;
            border-radius: 1px;
        }
    }

    .form-group {
        margin-bottom: 20px;

        select.form-control {
            height: 34px;
            padding: 6px 12px;
        }

        .help-block {
            color: #737373;
            margin-top: 5px;
        }
    }
}
