$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
section.ak-paper-submission {
    .form-copyright .control-label {
        text-align: left;
    }

    .drag-drop-zone {
        padding: 20px 0;
        border: 2px dashed #e7f1ff;
        cursor: pointer;
    }

    .drag-drop-zone-active {
        border: 2px dashed #005aa5;
    }

    .drop-zone-filled {
        padding: 20px 0;
    }

    .drop-zone-icon {
        color: #ff931d;
    }

    .paper-link {
        background-color: #ff931d;
        padding: 5px 5px 5px 10px;
        margin-top: -5px;
        margin-right: -10px;
    }
}
