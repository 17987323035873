$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
div.dashboard-organizer-section-header {
    margin-top: -5px;
    margin-bottom: 7px;

    .section-name {
        margin-top: 6px;
    }

    .section-progress {
        margin-top: 6px;
    }

    .proceeding-title {
        margin: 7px 0 0;
    }
}
