$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
section.dashboard-editor-publication-agreement,
section.dashboard-organizer-quote,
.modal-dialog .unit-price-modal {
    body {
        -webkit-print-color-adjust: exact;
    }

    .td-input-short {
        width: 75px;
    }

    .td-input-extra-short {
        width: 40px;
    }

    .td-input-medium {
        width: 100px;
    }

    .td-input-long {
        width: 150px;
    }

    .td-input-xlong {
        width: 220px;
    }

    .menu-divider {
        border-bottom: 1px solid #cfdbe2;
        padding-bottom: 8px;
    }

    .no-bottom-radius {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .section-name {
        margin-bottom: 15px;
    }

    .content-heading {
        padding-bottom: 0;
    }

    .panel-subheading {
        padding-top: 10px;
    }

    .tab-button {
        background-color: #457c8e;
        color: #ffffff;
        border-radius: 5px 5px 0 0;
        border-bottom: none;
    }

    .tab-button-light {
        background-color: #edf1f2;
        color: #515253;
        border-radius: 5px 5px 0 0;
        border-bottom: none;
    }

    .tab-button-light.selected {
        background-color: #457c8e;
        color: #ffffff;
        border-radius: 5px 5px 0 0;
        border-bottom: none;
    }

    .smaller-checkbox {
        margin-top: 6px;
        height: 20px;
    }

    //.btn-primary {
    //    background-color: #457c8e;
    //    color: #ffffff;
    //}

    .borderless th {
        border-top: none;
    }

    .docusign p {
        color: #ffffff;
        margin-bottom: 0;
    }

    .docusign hr {
        border-top-color: #656565;
        margin-top: 5px;
        margin-bottom: 3px;
    }

    /**
     * For the correct positioning of the placeholder element, the dnd-list and
     * it's children must have position: relative
     */
    ul[dnd-list],
    ul[dnd-list] > li {
        position: relative;
        overflow: hidden;
    }

    /**
     * The dnd-list should always have a min-height,
     * otherwise you can't drop to it once it's empty
     */
    ul[dnd-list] {
        min-height: 42px;
        padding-left: 0px;
    }

    /**
     * The dndDraggingSource class will be applied to
     * the source element of a drag operation. It makes
     * sense to hide it to give the user the feeling
     * that he's actually moving it.
     */
    ul[dnd-list] .dndDraggingSource {
        display: none;
    }

    /**
     * An element with .dndPlaceholder class will be
     * added to the dnd-list while the user is dragging
     * over it.
     */
    ul[dnd-list] .dndPlaceholder {
        display: block;
        //background-color: #ddd;
        min-height: 42px;
    }

    // taller placeholder needed for production schedule
    .production-schedule ul[dnd-list] .dndPlaceholder {
        min-height: 60px;
    }

    /**
     * The dnd-lists's child elements currently MUST have
     * position: relative. Otherwise we can not determine
     * whether the mouse pointer is in the upper or lower
     * half of the element we are dragging over. In other
     * browsers we can use event.offsetY for this.
     */
    ul[dnd-list] li {
        //background-color: #fff;
        border: none;
        border-radius: 4px;
        //border-top-right-radius: 4px;
        //border-top-left-radius: 4px;
        display: block;
        padding: 10px 15px;
        margin-bottom: -1px;
    }

    // remove bottom padding for production schedule
    .production-schedule ul[dnd-list] li {
        padding: 10px 15px 0;
    }

    ul[dnd-list] li.selected a {
    }

    ul.quote-sections {
        min-height: 42px;
        padding-left: 0px;
    }

    ul.quote-sections li {
        //color: $cps-primary-color-inverse !important;
        background-color: inherit;
        display: block;
        padding: 10px 15px;
        margin-bottom: -1px;
        border: none !important;
    }
}
