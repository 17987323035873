$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
section.cif-quotes {
    .quote-wrapper {
        padding: 10px 25px;
    }

    .quote-active-tab-area {
        padding: 20px;
        border: 1px solid #ddd;
        border-top: 0;
    }

    .nav-tabs > li.active > a,
    .nav-tabs > li.active > a:hover,
    .nav-tabs > li.active > a:focus {
        color: inherit;
        border-bottom-color: #f5f7fa;
    }

    .product-panel {
        padding-top: 20px;
        padding-right: 10px;
        /*height: 400px;*/
        /*overflow-y: scroll;*/
    }

    .product-panel::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    .product-panel::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    .product-panel > div {
        margin-top: 30px;
    }

    .remove-quote-disabled {
        cursor: not-allowed;
    }

    .control-label {
        text-align: left;
    }

    input[role="validate"].estimate.ng-invalid {
        background-color: #ffefeb !important;
        border-color: #ffa6a6;
    }
}
