$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
// Host container styles - Sets up the main container for the PDF viewer
:host {
    display: block;
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    border-radius: 4px;
    overflow: hidden;
}

// Main container for the PDF viewer - Provides layout structure
.pdf-viewer-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    border-radius: 4px;
    overflow: hidden;
}

// PDF iframe styles - Handles the actual PDF display
.inline-pdf {
    width: 100%;
    height: 100%;
    border: none;
    display: block;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

// Loading container styles - Centers and styles the loading indicator
.loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    background: rgba(255, 255, 255, 0.95);
    padding: 1.5rem;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

// Loading text styles - Provides feedback during PDF loading
.loading-text {
    margin-top: 1rem;
    color: #666;
    font-size: 0.9rem;
    font-weight: 500;
}

// Spinner container styles - Contains the bouncing dots animation
.spinner {
    margin: 0 auto;
    width: 70px;
    text-align: center;

    // Individual bouncing dots
    > div {
        width: 18px;
        height: 18px;
        background-color: #007bff;
        border-radius: 100%;
        display: inline-block;
        animation: bounce 1.4s infinite ease-in-out both;
        margin: 0 2px;

        // Staggered animation delays for each dot
        &.bounce1 {
            animation-delay: -0.32s;
        }

        &.bounce2 {
            animation-delay: -0.16s;
        }
    }
}

// Bounce animation keyframes for the loading dots
@keyframes bounce {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

// Error alert styles - Displays error messages when PDF fails to load
.pdf-viwer-container .alert {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    min-width: 200px;
    background: #fff;
    border: 1px solid #dc3545;
    color: #dc3545;
    margin: 0;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
