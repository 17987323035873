$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
.progress {
    margin-bottom: 0px !important;
}

.topnavbar {
    z-index: 113 !important;
}

.navigation-toolbar {
    margin-top: -4px;
}

.content-heading {
    font-size: 1.25em !important;
}

.text-primary {
    color: #457c8e !important;
}

.logo-text {
    color: #fff;
}

.cpir-validation-messages-error {
    .popover-title {
        background-color: #ff9332 !important;
        color: #fff !important;
    }

    .popover-content {
        color: #656565;
    }
}

.cpir-validation-messages-success {
    .popover-title {
        background-color: #00971e !important;
        color: #fff !important;
    }

    .popover-content {
        color: #656565;
    }
}
