$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
section.ak-open-access-copyright-release {
    .control-label {
        text-align: left !important;
    }

    .form-control[type="checkbox"] {
        height: 25px !important;
        width: 25px !important;
    }

    .checkout-button-glow {
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.075), 2px 4px 6px #656565 !important;
        outline: 0 none !important;
    }
}
