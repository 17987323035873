$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
section.dashboard-editor-toc-repository-versions {
    .align-top {
        vertical-align: top !important;
    }

    table.table.sticky {
        display: block;
        overflow-y: auto;
        height: calc(100vh - 330px);
        min-height: 200px;
    }

    table.table.sticky > thead > tr > th {
        position: sticky;
        top: 0;
        background-color: white;
    }
}
