$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
section.dashboard-configuration {
    //
    .nav-tabs > li > a {
        padding: 7px 30px !important;
    }

    .nav.nav-pills .active > a {
    }

    .nav.nav-pills > li > a:hover,
    .nav.nav-pills > li > a:focus {
        text-decoration: none;
    }

    .nav.nav-pills .active a:hover {
        color: #fff;
    }

    .nav.nav-pills .active a:focus {
        color: #fff;
    }

    .form-horizontal .control-label {
        text-align: left !important;
    }
}
