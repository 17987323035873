$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
div.icx-tab {
    max-height: 260px;
    ul {
        display: grid;
        grid-template-columns: min-content 1fr;
        overflow-wrap: break-word;
    }

    li {
       display: contents;
       list-style-type: none;
    }

    li::before {
        content: '\00b7';
        font-size: 300%;
        line-height: 48%;
    }

    .col-xs-10.ng-binding.ng-scope {
        min-height: 22px;
    }

    .odd-numbered-row {
        background-color: #abcdef
    }

    .even-numbered-row {
        background-color: #f2dede;
    }

}
div.adjust-scroll::-webkit-scrollbar-button {
        height: 50px; //for vertical scrollbar
}

div.adjust-scroll::-webkit-scrollbar-thumb{
    width: 30px;
    border-radius: 30px;
}
.icx-tab-header{
    text-align:left;
    padding-left: 5px;
    padding-right: 20px;
    padding-top: 15px;
}


