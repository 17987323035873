$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
section.dashboard-editor-toc {
    .panel-heading .btn-toolbar {
        margin-top: -5px !important;
    }

    .btn {
        font-size: 10.5px;
    }

    .panel-grid {
        border: none;
        overflow: hidden;

        .panel-heading {
            border: 1px solid #cfdbe2;
            position: sticky;
            top: 0;
            z-index: 6;
            background: white;

            .panel-header-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                // Style for the title text
                .panel-title {
                    font-size: 14px;
                    font-weight: 500;
                    color: inherit;
                }

                // Style for the customize columns button
                .column-settings-btn {
                    background-color: transparent;
                    border: 1px solid $cps-primary-color-dark;
                    color: $cps-primary-color-very-dark;
                    padding: 4px 12px;
                    transition: all 0.2s ease;

                    &:hover {
                        background-color: rgba(255, 255, 255, 0.5);
                        border-color: $cps-primary-color-very-dark;
                    }

                    // Icon styling
                    i {
                        margin-right: 5px;
                    }

                    // Text styling
                    span {
                        font-size: 12px;
                    }
                }
            }
        }

        .table {
            margin-bottom: 0 !important;
        }
    }

    th {
        padding: 10px 8px !important;
    }

    th,
    td {
        font-size: 12px;
        color: #454545;
        border-color: #eee !important;
    }

    th a,
    th a:focus,
    th a:visited {
        font-size: 12px;
        color: #888;
    }

    // Toolbar container
    .table-toolbar-container {
        background: #f8f9fa;
        padding: 12px;
        width: 100%;
        position: relative; // Changed from sticky to relative
        z-index: 4;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        transition: left 0.1s ease; // Smooth transition for position changes

        // Container should fill the width of the table
        min-width: 100%;
        box-sizing: border-box;

        // Ensure container stays above table content during scroll
        &.is-floating {
            background: #f8f9fa;
            margin-left: 0;
            margin-right: 0;
        }

        // Toolbar row styling
        .toolbar-row {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            gap: 8px;
            padding: 0;
            transition: left 0.1s ease; // Smooth transition for position changes

            &:not(:last-child) {
                border-bottom: 1px solid #e9ecef;
                margin-bottom: 4px;
                padding-bottom: 8px;
            }
        }

        // Search container
        .search-container {
            width: 250px;
            margin-right: 16px;

            .search {
                height: 30px;
                padding: 5px 10px;
                border-radius: 3px;
                border: 1px solid #ced4da;

                &:focus {
                    border-color: #80bdff;
                    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
                }
            }
        }

        // Divider between button groups
        .group-divider {
            width: 1px;
            height: 50px;
            background: linear-gradient(
                to bottom,
                transparent,
                #aaa 20%,
                #aaa 80%,
                transparent
            );
            opacity: 0.8;
            flex: 0 0 auto;
            margin: -4px 4px;
        }

        // Button groups
        .button-group {
            display: flex;
            align-items: center;
            padding: 0 8px;

            // Button styling
            .btn {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 4px 8px;
                min-width: 60px;
                height: auto;
                white-space: nowrap;
                border: 1px solid transparent;
                margin: 0 1px;

                // Icon styling
                i {
                    font-size: 14px;
                    margin-bottom: 2px;
                }

                // Text label
                span {
                    font-size: 11px;
                    line-height: 1.2;
                }

                &:hover {
                    background: #e9ecef;
                    border-color: #ced4da;
                }

                &:active {
                    background: #dee2e6;
                    border-color: #adb5bd;
                }
            }
        }
    }

    .btn-sm {
        padding-right: 9px;
        padding-left: 9.8px;
        line-height: 1.5;
    }

    .btn-toolbar .btn.btn-smaller {
        font-size: 10.5px;
    }

    .btn-reorder.btn.active {
        background-color: #ffefd3 !important;
    }

    .is-not-reordering input {
        width: 30px;
        cursor: pointer;
    }

    span.form-control {
        border: 1px solid #fff !important;
    }

    input.search {
        font-size: 12px;
        height: 28px;
    }

    input[type="checkbox"] {
        margin: 0 !important;
        height: auto !important;
    }

    input[type="number"] {
        height: auto !important;
        margin: 0 !important;
        padding: 0px 5px;
        border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
    }

    .panel-body {
        border: none !important;
        position: relative;
        padding: 0 !important;
    }

    .table-container {
        position: relative;
        margin: 0;
        padding: 0;
        background: white;
        overflow-x: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            height: 8px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #ddd;
            border-radius: 4px;
        }

        // Shadow for scroll indication
        &.is-scrolled {
            .sequence-column::after {
                opacity: 1;
            }

            .custom-fixed-column::after {
                opacity: 1;
            }
        }

        table {
            border-collapse: collapse;
            border-spacing: 0;
            margin-bottom: 0;
        }

        thead {
            /* Regular header rows */
            tr:first-child td,
            tr:nth-child(2) td {
                background: white;
                z-index: 5;
                padding: 0 !important;
            }

            tr:last-child {
                &.floating-header {
                    background: white; // Ensure solid background
                    z-index: 6;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -16px;
                        height: 16px;
                        background: linear-gradient(
                            180deg,
                            rgba(0, 0, 0, 0.16) 0%,
                            rgba(0, 0, 0, 0) 100%
                        );
                        pointer-events: none;
                        z-index: 5;
                    }
                }
            }
        }
    }

    .fixed-column {
        position: sticky;
        background: white;
        z-index: 2;
        margin: -1px;
        padding: 1px;
    }

    .select-column {
        left: 0;
        width: 70px;
        min-width: 70px;
        max-width: 70px;
        z-index: 3;

        &.fixed-column::before {
            content: "";
            position: absolute;
            top: -1px;
            left: -1px;
            bottom: -1px;
            width: 1px;
            background: #eee;
            z-index: 5;
        }

        &.fixed-column::after {
            content: "";
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -1px;
            width: 1px;
            background: #eee;
            z-index: 5;
        }
    }

    .sequence-column {
        position: sticky;
        left: 70px;
        width: 60px;
        min-width: 60px;
        max-width: 60px;
        z-index: 3;

        &.fixed-column::after {
            content: "";
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -1px;
            width: 1px;
            background: #eee;
            z-index: 5;
        }
    }

    .custom-fixed-column {
        position: sticky;
        left: 130px; // Positioned after the sequence column
        z-index: 3;
        margin: -1px;
        padding: 1px;

        &.fixed-column::after {
            content: "";
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -1px;
            width: 1px;
            background: #eee;
            z-index: 5;
        }
    }

    .last-fixed-column {
        &.fixed-column::after {
            content: "";
            position: absolute;
            top: -1px;
            right: -16px;
            bottom: -1px;
            width: 16px;
            background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.16) 0%,
                rgba(0, 0, 0, 0) 100%
            );
            z-index: 5;
            opacity: 0;
            transition: opacity 0.3s;
        }
    }

    td,
    th {
        white-space: nowrap;

        &[data-column-id="title"] {
            width: 35%;
            min-width: 300px;
            white-space: normal;
        }

        &:not([data-column-id="title"]):not(.fixed-column) {
            min-width: 50px;
            max-width: none;
            width: auto;
        }
    }

    .table-bordered {
        border: 1px solid #eee;

        > thead > tr > th,
        > tbody > tr > td {
            border: 1px solid #eee;
        }

        > tbody > tr:hover {
            background-color: #f5f5f5;

            td {
                background-color: #f5f5f5;
            }
        }
    }

    .scroll-helper {
        position: fixed;
        z-index: 1000;
        width: 30px;
        height: 60px;
        background: rgba(255, 255, 255, 0.9);
        border: 1px solid #ddd;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition:
            opacity 0.3s,
            background-color 0.3s;

        &:hover {
            background: rgba(255, 255, 255, 1);
        }

        &.left {
            left: 30px;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: -16px;
                bottom: 0;
                width: 16px;
                background: linear-gradient(
                    90deg,
                    rgba(0, 0, 0, 0.16) 0%,
                    rgba(0, 0, 0, 0) 100%
                );
                pointer-events: none;
            }
        }

        &.right {
            right: 30px;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: -16px;
                bottom: 0;
                width: 16px;
                background: linear-gradient(
                    -90deg,
                    rgba(0, 0, 0, 0.16) 0%,
                    rgba(0, 0, 0, 0) 100%
                );
                pointer-events: none;
            }
        }

        i {
            color: #666;
            font-size: 20px;
        }
    }

    // Responsive Breakpoints
    @media (max-width: 1600px) {
        .table-toolbar-container {
            .button-group {
                padding: 6px;
            }
        }
    }

    @media (max-width: 1400px) {
        .table-toolbar-container {
            .toolbar-row {
                gap: 6px;
            }

            .button-group {
                flex-wrap: wrap;
                justify-content: flex-start;
                flex-basis: auto;
                margin-bottom: 6px;

                .btn {
                    flex: 0 1 auto;
                    min-width: 60px;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .table-toolbar-container {
            .button-group {
                flex-basis: calc(33.333% - 8px);
            }
        }
    }

    @media (max-width: 992px) {
        .table-toolbar-container {
            padding: 6px;

            .search-container {
                width: 100%;
                margin-bottom: 8px;
                margin-right: 0;
            }

            .button-group {
                flex-basis: calc(50% - 8px);

                .btn {
                    flex: 1 1 auto;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .table-toolbar-container {
            .button-group {
                flex-basis: 100%;

                .btn {
                    min-width: 70px;
                }
            }
        }
    }

    @media (max-width: 576px) {
        .table-toolbar-container {
            padding: 4px;

            .button-group {
                padding: 4px;

                .btn {
                    padding: 4px;

                    span {
                        font-size: 10px;
                    }

                    i {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
