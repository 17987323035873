$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
div.dashboard-editor {
    > section {
        margin-left: 0 !important;
    }

    > footer {
        margin-left: 0 !important;
    }
}

/* Fast Mode Navbar Styles */
.navbar.topnavbar-fast-mode {
    height: 50px;
    min-height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    background-color: #0862a2;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    padding: 0 15px;

    .navbar-header {
        float: left;
        display: flex;
        align-items: center;
        width: auto;
        max-width: 80px; /* Reduced to give more space to title */
        height: 50px;
        margin-right: 10px;

        .nav.navbar-nav {
            float: left;
            display: flex;
            align-items: center;
            height: 50px;
            margin: 0;

            > li {
                float: left;
                display: flex;
                align-items: center;
                height: 50px;
            }
        }
    }

    .navbar-brand-compact {
        padding: 5px 10px 5px 0;
        height: 50px;
        display: flex;
        align-items: center;

        img.cps-logo-small,
        img.pubhub-logo-small {
            height: 30px;
            width: auto;
        }
    }

    .fast-title-container {
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        margin-left: 10px;
        height: 50px;
        padding: 0 10px;
        max-width: 35%; /* Wider to show more of title */
        min-width: 100px;
        overflow: hidden;
    }

    .fast-mode-title {
        display: inline-block;
        color: white;
        font-size: 14px;
        padding: 0;
        vertical-align: middle;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .nav-wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;

        .nav.navbar-nav {
            display: flex;
            align-items: center;
            height: 50px;
            margin: 0;

            &.navbar-left {
                margin-right: auto;
            }

            &.navbar-right {
                margin-left: auto;
                display: flex;
                flex-direction: row;

                > li {
                    margin-left: 5px;

                    &.dropdown {
                        position: relative;

                        .dropdown-toggle {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            padding: 0 10px;

                            .user-email {
                                margin: 0 5px;
                                max-width: 150px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }

                        /* Let UI Bootstrap handle dropdown visibility */
                        &.open {
                            z-index: 1035; /* Ensure dropdown container has high z-index */
                        }
                    }

                    /* Style the dropdown menu when appended to body */
                    .fast-mode-dropdown {
                        min-width: 200px;
                        padding: 5px 0;

                        .dropdown-header {
                            padding: 8px 15px;
                            font-size: 13px;
                            color: #333;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .divider {
                            margin: 5px 0;
                        }

                        > li > a {
                            padding: 8px 15px;
                            display: flex;
                            align-items: center;

                            i {
                                margin-right: 8px;
                            }
                        }
                    }
                }

                .btn {
                    white-space: nowrap;
                }
            }

            > li {
                float: left;
                display: flex;
                align-items: center;
                height: 50px;

                > a {
                    padding: 0 10px;
                    color: white;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    &.btn {
                        margin: 0 2px;
                        padding: 5px 10px;
                        color: #333;
                        height: auto;
                    }

                    &.highlight-nav-link {
                        color: #fff;
                    }

                    .user-email {
                        max-width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: inline-block;
                        margin: 0 5px;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

/* Content adjustments for fast mode */
body.dashboard-layout-toc-fast {
    /* Add padding to account for fixed navbar */
    padding-top: 50px;
    margin: 0;
    overflow-x: hidden;

    /* Adjust the overall wrapper in fast mode */
    .wrapper.dashboard-editor.fast-mode {
        margin-top: 0;
        padding-top: 0;
    }

    /* Position the fast mode section properly */
    section.dashboard-editor-toc-fast {
        margin-top: 0;
        padding-top: 0;
        top: 50px !important; /* Position right below the navbar */
        height: calc(
            100vh - 50px
        ) !important; /* Adjust height to account for navbar */
    }

    /* Table toolbar container specific positioning */
    .table-toolbar-container {
        position: sticky !important;
        top: 0 !important;
        z-index: 1020 !important;
    }

    /* Fix table header positioning */
    .table-inner-container {
        table {
            thead {
                position: sticky !important;
                top: 0 !important;
                z-index: 1010 !important;
            }
        }
    }
}

/* Global styles for dropdowns when appended to body in fast mode */
body.has-fastmode-navbar {
    /* Ensure dropdown menus appended to body are styled and positioned correctly */
    .dropdown-menu.dropdown-menu-right {
        z-index: 1040 !important; /* Higher than navbar */

        &.fast-mode-dropdown {
            min-width: 200px;
            padding: 5px 0;

            .dropdown-header {
                padding: 8px 15px;
                font-size: 13px;
                color: #333;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .divider {
                margin: 5px 0;
            }

            > li > a {
                padding: 8px 15px;
                display: flex;
                align-items: center;

                i {
                    margin-right: 8px;
                }
            }
        }
    }
}

/* Fix for scroll helpers that might be hidden */
.scroll-helper {
    z-index: 900;
    position: fixed;
}
