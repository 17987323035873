$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
.cps {
    a {
        color: $cps-primary-color-inverse;
        text-decoration: none;
    }
    table .text-primary,
    table .fa.fa-cog {
        color: $cps-primary-color-inverse !important;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .cursor-pointer:hover {
        color: $cps-primary-color-dark !important;
    }
    .bg-primary {
        background-color: $cps-primary-color !important;
    }
    .topnavbar,
    .navbar-header {
        border: 0 !important;
        background: $cps-primary-color-dark none !important;
    }
    @media only screen and (min-width: 769px) {
        .topnavbar .highlight-nav-link {
            background-color: #004d8d !important;
        }
    }
    .panel-primary > .panel-heading {
        color: $cps-primary-color-very-dark !important;
        background-color: $cps-primary-color !important;
    }
    .panel-primary > .panel-heading.heading-inverse {
        background-color: #fff !important;
        color: $cps-primary-color-inverse !important;
    }
    @media only screen and (max-width: 768px) {
        .topnavbar .navbar-right > li > a,
        .topnavbar .navbar-nav > .open > a {
            color: #004985;
        }
        .topnavbar .navbar-nav > li > a:hover,
        .topnavbar .navbar-nav > .open > a:hover,
        .topnavbar .navbar-nav > li > a:focus,
        .topnavbar .navbar-nav > .open > a:focus {
            color: #004985;
        }
    }
    .nav-link-highlight {
        color: white !important;
        background-color: $cps-primary-color-dark !important;
    }
    .nav.nav-pills .active > a {
        color: $cps-primary-color-inverse !important;
        background-color: $cps-primary-color !important;
    }
    .nav.nav-pills > li > a:hover,
    .nav.nav-pills > li > a:focus {
        background-color: $cps-primary-color-very-light;
        color: $cps-primary-color-inverse;
    }
    ul[dnd-list] li:hover,
    ul[dnd-list] li:focus {
        background-color: $cps-primary-color-very-light;
        color: $cps-primary-color-inverse;
    }
    /* Buttons */
    .btn-primary {
        color: $cps-primary-color-inverse;
        background-color: $cps-primary-color !important;
        border-color: $cps-primary-color !important;
    }
    .btn-primary:hover {
        color: $cps-primary-color-inverse;
        background-color: $cps-primary-color !important;
        border-color: $cps-primary-color !important;
    }
    .btn.active {
        outline: 0;
        background-color: $cps-primary-color;
        color: $cps-primary-color-inverse;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .uib-datepicker {
        .btn-default:hover {
            color: $cps-primary-color-inverse;
            background-color: $cps-primary-color;
            border-color: #d6d6d6;
        }

        .btn-default.focus {
            color: $cps-primary-color-inverse;
            background-color: $cps-primary-color;
            border-color: #d6d6d6;
        }

        .btn-default:active {
            color: $cps-primary-color-inverse;
            background-color: $cps-primary-color;
            border-color: #d6d6d6;
        }

        .open > .dropdown-toggle.btn-default {
            color: $cps-primary-color-inverse;
            background-color: $cps-primary-color;
            border-color: #d6d6d6;
        }

        .text-info {
            color: $cps-primary-color-inverse !important;
        }

        .btn-default.active .text-info {
            color: #fff !important;
        }

        .text-info:hover {
            color: #fff !important;
        }
    }

    .text-inverse {
        color: $inverse-text-color !important;
    }

    footer {
        height: 56px !important;
        padding: 18px 27px !important;
        background-color: #808080 !important;
        color: #fff !important;
    }
}
.pubAggmtBody {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 12px !important;
    line-height: 1.52857143;
    color: #515253;
    background-color: #f5f7fa;

    .panel,
    panel-heading {
        border-radius: 0 !important;
    }

    .panel-heading {
        padding-top: 30px !important;
    }
}
