$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
section.dashboard-editor-home {
    .content-wrapper > h3,
    .content-wrapper > .content-heading {
        margin-bottom: 0 !important;
        position: fixed;
        top: 75px;
        z-index: 1000;
        transition: top 0.2s;
    }

    div.panel-primary > .panel-heading {
        border-radius: 0;
        //color: $cps-primary-color-inverse !important;
        //background-color: $cps-primary-color !important;
        padding: 7px 10px;
    }

    div.panel-primary > .panel-body {
        padding: 10px 10px 0 !important;
    }

    .btn-toolbar .btn {
        margin-bottom: 17px !important;
    }

    ul.dropdown-menu a {
        text-align: left !important;
        padding: 3px 10px !important;
    }

    #topButton {
        position: fixed; /* Fixed/sticky position */
        bottom: 20px; /* Place the button at the bottom of the page */
        right: 30px; /* Place the button 30px from the right */
        z-index: 99; /* Make sure it does not overlap */
        cursor: pointer; /* Add a mouse pointer on hover */
        padding: 15px; /* Some padding */
        border-radius: 10px; /* Rounded corners */
        font-size: 18px; /* Increase font size */
        width: 60px;
    }

    #topButton:hover {
        border: none;
        background-color: #555; /* Add a dark-grey background on hover */
    }
}
