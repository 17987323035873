$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
.toc-entry-panel {
    .change-request-input {
        box-shadow:
            0 2px 2px rgba(0, 0, 0, 0.075) inset,
            0 0 8px #ff902b !important;
        outline: 0 none !important;
    }

    .change-request-label {
        color: #ff902b;
        font-weight: bold;
    }

    // Author section styling
    .author-section {
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        padding: 20px 15px 0 15px;
        margin: 25px 0;
        position: relative;

        // Section title
        &::before {
            content: "Author Information";
            position: absolute;
            top: -12px;
            left: 15px;
            background: #fff;
            padding: 0 10px;
            color: #666;
            font-size: 14px;
        }

        // Subtle gradient background
        background: linear-gradient(to bottom, #fafafa, #ffffff);
    }

    // Author selector styling
    .author-selector {
        margin-bottom: 20px;

        .input-group {
            .form-control {
                z-index: 0;
            }

            .input-group-addon {
                //background-color: #fff;
                //border-left: none;
                //color: #666;
                min-width: 60px;
                padding: 6px 12px;
                font-size: 13px;
            }

            .input-group-btn {
                .btn {
                    margin-left: 2px;
                    min-width: 80px;

                    &:hover {
                        background-color: #f5f5f5;
                    }

                    &:disabled {
                        opacity: 0.6;
                    }
                }
            }
        }
    }

    // Author detail fields
    .author-details {
        padding: 15px;

        .form-group {
            margin-bottom: 12px;

            .control-label {
                color: #555;
            }

            .form-control {
                border-color: #e0e0e0;
                box-shadow: none;

                &:focus {
                    border-color: #80bdff;
                    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
                }
            }
        }
    }

    // Ensure dropdown options are visible and styled
    select.form-control {
        option {
            padding: 6px;

            &:hover {
                background-color: #f5f5f5;
            }
        }
    }
}
