$cps-primary-color: #abcdef;
$cps-primary-color-dark: #005aa5;
$cps-primary-color-very-dark: #002142;
$cps-primary-color-very-light: #e7f1ff;
$cps-primary-color-inverse: #002a55;

$pubhub-primary-color: #ffe8a5;
$pubhub-primary-color-dark: #ffcc33;
$pubhub-primary-color-very-dark: #584216;
$pubhub-primary-color-very-light: #d2cfcd;
$pubhub-primary-color-inverse: #002a55;

$inverse-text-color: #000;

//7aa4c7
// Variables are automatically included by the build process
// (see gulpfile.js which prepends sass-variables.scss to all SCSS files)

// Define any additional variables we need
$cps-primary-color-light: lighten($cps-primary-color, 10%);
$navbar-height: 50px;
$toolbar-height: 60px;

// Fast Mode styling for the Table of Contents
section.dashboard-editor-toc-fast {
    display: flex;
    flex-direction: column;
    height: calc(
        100vh - #{$navbar-height}
    ); // Take full viewport height minus navbar
    width: 100%;
    overflow: hidden;
    position: fixed;
    top: $navbar-height; // Position below the navbar
    left: 0;
    background-color: #fff;
    z-index: 990; // Lower than navbar but higher than other content

    // We no longer need the fast-mode-header as it's replaced by the navbar
    .fast-mode-header {
        display: none; // Hide the header since we're using the navbar
    }

    // Main content area
    .fast-mode-content {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 100%;

        .panel-fast-mode {
            flex: 1;
            margin: 0;
            border: none;
            border-radius: 0;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            height: 100%;

            // Table toolbar container
            .table-toolbar-container {
                background-color: $cps-primary-color-light;
                border-bottom: 1px solid $cps-primary-color;
                padding: 10px;
                z-index: 1010; // Higher than table but lower than navbar
                position: sticky;
                top: 0;

                // Ensure toolbar doesn't shrink
                flex-shrink: 0;
                min-height: $toolbar-height;

                .toolbar-row {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 5px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &.expanded-toolbar {
                        border-top: 1px solid rgba(0, 0, 0, 0.1);
                        padding-top: 5px;
                        margin-top: 5px;
                    }

                    .search-container {
                        width: 200px;

                        input.search {
                            height: 30px;
                            padding: 5px 10px;
                        }
                    }

                    .group-divider {
                        width: 1px;
                        height: 30px;
                        background-color: rgba(0, 0, 0, 0.1);
                        margin: 0 5px;
                    }

                    .button-group {
                        display: flex;
                        gap: 5px;
                        flex-wrap: wrap;

                        button {
                            display: inline-flex;
                            align-items: center;
                            gap: 5px;
                            height: 30px;
                            padding: 0 10px;
                            white-space: nowrap;
                            font-size: 11px;

                            &.active {
                                background-color: $cps-primary-color;
                                color: white;
                            }
                        }
                    }
                }
            }

            // Table scroll container with inner scrolling
            .table-scroll-container {
                flex: 1;
                position: relative;
                overflow: hidden;
                height: calc(100% - #{$toolbar-height}); // Height minus toolbar

                // Scroll helpers
                .scroll-helper {
                    position: absolute;
                    width: 30px;
                    height: 80px;
                    background-color: rgba(0, 0, 0, 0.2);
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    z-index: 1005;
                    transition: opacity 0.2s;
                    top: 50%;
                    transform: translateY(-50%);

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.3);
                    }

                    &.left {
                        left: 0;
                        border-radius: 0 4px 4px 0;
                    }

                    &.right {
                        right: 0;
                        border-radius: 4px 0 0 4px;
                    }

                    i {
                        font-size: 18px;
                    }
                }

                // Table inner container with scrollbars
                .table-inner-container {
                    overflow: hidden !important; // Hide the outer container scrollbar
                    position: relative;
                    height: 100%;
                    width: 100%;

                    // Create a single visible scrollbar
                    .fixed-header-row {
                        overflow-x: hidden;
                        position: sticky;
                        top: 0;
                        z-index: 1002;
                        display: flex;
                        table-layout: fixed;
                        width: max-content;
                        min-width: 100%;
                    }

                    // Make the fixed-header-row scroll with the virtual container
                    md-virtual-repeat-container {
                        overflow-x: auto !important;
                        width: 100%;

                        .md-virtual-repeat-scroller {
                            overflow-x: auto !important;
                            width: 100%;

                            // When the scroller scrolls, update the header position
                            &::-webkit-scrollbar {
                                height: 12px;
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: #c1c1c1;
                                border-radius: 6px;
                            }
                        }
                    }
                }
            }

            // Loading indicator
            .loading-container {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                color: $cps-primary-color;
            }
        }
    }
}

// Additional modifier for hover, active states
.dashboard-editor-toc-fast {
    .hover-highlight:hover {
        background-color: rgba($cps-primary-color-light, 0.2);
        border-radius: 3px;
    }
}

// Modal styles for summary data
.toc-summary-modal {
    .modal-dialog {
        max-width: 800px;
    }

    .modal-body {
        max-height: 70vh;
        overflow-y: auto;
    }

    .modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .modal-title {
            margin: 0;
            line-height: 1.5;
        }

        .close {
            font-size: 24px;
            padding: 0;
            margin: 0;
            line-height: 1;
            opacity: 0.6;

            &:hover {
                opacity: 1;
            }
        }
    }
}

// Status chart and legend styles
.status-chart {
    height: 200px;
    margin-bottom: 15px;
    position: relative;
}

.status-legend {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .legend-item {
        display: flex;
        align-items: center;
        margin-right: 15px;
        margin-bottom: 10px;
        font-size: 12px;

        .legend-color {
            display: inline-block;
            width: 14px;
            height: 14px;
            border-radius: 3px;
            margin-right: 5px;
        }

        .legend-label {
            white-space: nowrap;
        }
    }
}

// Clickable no-show link
.no-show-link {
    color: $cps-primary-color-dark !important;
    cursor: pointer;
    text-decoration: underline !important;

    &:hover {
        text-decoration: underline;
        color: lighten($cps-primary-color-dark, 20%) !important;
    }
}

// Virtual scroll container and elements
.virtual-scroll-container {
    height: 100%;
    width: 100%;
    background-color: white;
    overflow: auto;

    // Ensure the container takes the full height
    &.md-virtual-repeat-container {
        width: 100%;
        height: calc(100% - 40px); // Account for headers
        position: relative;

        .md-virtual-repeat-offsetter {
            // Ensure offsetter takes full width
            width: 100% !important;
            padding-right: 0 !important;
        }

        .md-virtual-repeat-scroller {
            // Ensure scroller takes full width
            width: 100% !important;
            margin-right: 0 !important;
            overflow-x: auto !important;
        }

        .md-virtual-repeat-sizer {
            // Ensure sizer takes full width
            width: 100% !important;
        }
    }

    // Virtual repeat rows
    .virtual-row {
        height: 40px;
        min-height: 40px;
        display: flex;
        align-items: center;
        width: max-content; // Allow rows to expand beyond container
        min-width: 100%; // Ensure at least full container width
        border-bottom: 1px solid #ddd;
        position: relative;
        box-sizing: border-box;

        &:hover {
            background-color: rgba($cps-primary-color-light, 0.1);
        }

        // Cells within virtual rows
        .fixed-column,
        .cell {
            padding: 6px 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 100px;
            height: 100%;
            display: flex;
            align-items: center;
            border-right: 1px solid #f0f0f0;
            box-sizing: border-box;
            flex-shrink: 0; // Prevent columns from shrinking

            &.select-column {
                width: 80px;
                min-width: 80px;
                max-width: 80px;
                position: sticky;
                left: 0;
                z-index: 995;
                background-color: white;
                box-shadow: 2px 0 4px rgba(0, 0, 0, 0.05);

                input[type="checkbox"] {
                    height: 16px;
                    width: 16px;
                    margin: 0;
                    padding: 0;
                }
            }

            &.sequence-column {
                width: 80px;
                min-width: 80px;
                max-width: 80px;
                position: sticky;
                left: 80px;
                z-index: 995;
                background-color: white;

                input[type="number"] {
                    width: 100%;
                    padding: 2px 5px;
                    height: 24px;
                }
            }

            &.custom-fixed-column {
                position: sticky;
                left: 160px;
                min-width: 200px;
                z-index: 995;
                background-color: white;
            }

            &.last-fixed-column {
                box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
            }

            // Ensure specific column widths match header widths
            &[data-column-id="type"] {
                width: 100px;
                min-width: 100px;
                max-width: 100px;
            }
            &[data-column-id="title"] {
                width: 500px;
                min-width: 500px;
            }
            &[data-column-id="pages"] {
                width: 80px;
                min-width: 80px;
                max-width: 80px;
            }
            &[data-column-id="pageNumber"] {
                width: 80px;
                min-width: 80px;
                max-width: 80px;
            }
            &[data-column-id="version"] {
                width: 80px;
                min-width: 80px;
                max-width: 80px;
            }
            &[data-column-id="file"] {
                width: 100px;
                min-width: 100px;
                max-width: 100px;
            }
            &[data-column-id="stamp"] {
                width: 100px;
                min-width: 100px;
                max-width: 100px;
            }
            &[data-column-id="supplement"] {
                width: 120px;
                min-width: 120px;
                max-width: 120px;
            }
            &[data-column-id="pitstop"] {
                width: 80px;
                min-width: 80px;
                max-width: 80px;
            }
            &[data-column-id="productionStatus"] {
                width: 120px;
                min-width: 120px;
                max-width: 120px;
            }
            &[data-column-id="promo"] {
                width: 80px;
                min-width: 80px;
                max-width: 80px;
            }
            &[data-column-id="paperId"] {
                width: 100px;
                min-width: 100px;
                max-width: 100px;
            }
            &[data-column-id="similarity"] {
                width: 100px;
                min-width: 100px;
                max-width: 100px;
            }
            &[data-column-id="copyrightType"] {
                width: 120px;
                min-width: 120px;
                max-width: 120px;
            }
            &[data-column-id="authors"] {
                width: 80px;
                min-width: 80px;
                max-width: 80px;
            }
            &[data-column-id="doi"] {
                width: 250px;
                min-width: 250px;
                max-width: 250px;
            }
        }
    }
}

// Fixed header row styling
.fixed-header-row {
    display: flex;
    height: 40px;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: max-content; // Allow header to expand with content
    min-width: 100%; // Ensure at least full container width
    box-sizing: border-box;

    .header-cell {
        padding: 8px;
        font-weight: bold;
        white-space: nowrap;
        min-width: 100px;
        display: flex;
        align-items: center;
        border-right: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        box-sizing: border-box;
        flex-shrink: 0; // Prevent columns from shrinking

        &.fixed-column {
            position: sticky;
            background-color: #f5f5f5;

            &.select-column {
                width: 80px;
                min-width: 80px;
                max-width: 80px;
                left: 0;
                z-index: 1002;
            }

            &.sequence-column {
                width: 80px;
                min-width: 80px;
                max-width: 80px;
                left: 80px;
                z-index: 1001;
            }

            &.custom-fixed-column {
                left: 160px;
                min-width: 200px;
                z-index: 1001;
            }

            &.last-fixed-column {
                box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
            }
        }

        a {
            color: $cps-primary-color-dark;
            text-decoration: none;
            cursor: pointer;
            display: block;
            width: 100%;
        }

        // Ensure specific column widths match cell widths
        &[data-column-id="type"] {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
        }
        &[data-column-id="title"] {
            width: 500px;
            min-width: 500px;
        }
        &[data-column-id="pages"] {
            width: 80px;
            min-width: 80px;
            max-width: 80px;
        }
        &[data-column-id="pageNumber"] {
            width: 80px;
            min-width: 80px;
            max-width: 80px;
        }
        &[data-column-id="version"] {
            width: 80px;
            min-width: 80px;
            max-width: 80px;
        }
        &[data-column-id="file"] {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
        }
        &[data-column-id="stamp"] {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
        }
        &[data-column-id="supplement"] {
            width: 120px;
            min-width: 120px;
            max-width: 120px;
        }
        &[data-column-id="pitstop"] {
            width: 80px;
            min-width: 80px;
            max-width: 80px;
        }
        &[data-column-id="productionStatus"] {
            width: 120px;
            min-width: 120px;
            max-width: 120px;
        }
        &[data-column-id="promo"] {
            width: 80px;
            min-width: 80px;
            max-width: 80px;
        }
        &[data-column-id="paperId"] {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
        }
        &[data-column-id="similarity"] {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
        }
        &[data-column-id="copyrightType"] {
            width: 120px;
            min-width: 120px;
            max-width: 120px;
        }
        &[data-column-id="authors"] {
            width: 80px;
            min-width: 80px;
            max-width: 80px;
        }
        &[data-column-id="doi"] {
            width: 250px;
            min-width: 250px;
            max-width: 250px;
        }
    }
}

// Custom scrollbar styles
.synchronized-scroll {
    .md-virtual-repeat-scroller {
        overflow-x: auto;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            height: 12px;
            width: 12px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #c1c1c1;
            border-radius: 6px;
        }
    }
}

// Row count indicator styling
.row-count-indicator {
    position: absolute;
    top: 45px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 11px;
    z-index: 1010;
    opacity: 0.7;

    &:hover {
        opacity: 1;
    }
}

// Improve visibility of active rows
.virtual-row {
    background-color: lavender;
}

.active-row {
    background-color: $cps-primary-color !important;
}

.popover-z-index {
    z-index: 10000 !important;
}

// Critical Angular Material Virtual Repeat fixes
md-virtual-repeat-container {
    // Essential fixes for visibility and sizing
    display: block !important;
    min-height: 300px !important;
    height: calc(100% - 40px) !important;
    width: 100% !important;

    // Fix layout issues
    position: relative !important;
    transform: translateZ(0);
    will-change: transform;

    // Make sure container is visible
    opacity: 1 !important;
    visibility: visible !important;

    // Fix for Angular Material layout issues
    .md-virtual-repeat-scroller {
        position: absolute;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100% !important;
        height: 100% !important;
    }

    .md-virtual-repeat-sizer {
        width: 100% !important;
    }

    // Debug styling
    .debug-placeholder {
        padding: 20px;
        text-align: center;
        background-color: #f8f8f8;
        border: 1px dashed #ccc;
        margin: 10px;
    }
}

// Fixed column improvements
.virtual-row {
    // Fixed columns should have z-index higher than regular cells
    .fixed-column {
        position: sticky !important;
        z-index: 995 !important;
        background-color: white !important;

        &.select-column {
            left: 0 !important;
            border-right: 1px solid #ddd !important;
        }

        &.sequence-column {
            left: 80px !important;
            border-right: 1px solid #ddd !important;
        }

        &.custom-fixed-column {
            left: 160px !important;
            border-right: 1px solid #ddd !important;
        }

        &.last-fixed-column {
            box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.2) !important;
        }
    }
}

// Fixed header cell improvements
.fixed-header-row {
    .header-cell.fixed-column {
        position: sticky !important;
        z-index: 1002 !important;
        background-color: #f5f5f5 !important;

        &.select-column {
            left: 0 !important;
            border-right: 1px solid #ddd !important;
        }

        &.sequence-column {
            left: 80px !important;
            border-right: 1px solid #ddd !important;
        }

        &.custom-fixed-column {
            left: 160px !important;
            border-right: 1px solid #ddd !important;
        }

        &.last-fixed-column {
            box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.2) !important;
        }
    }
}

// Ensure empty spaces in fixed columns have background
.md-virtual-repeat-scroller {
    background-color: white;
}

// Add universal box-sizing to avoid layout issues
* {
    box-sizing: border-box;
}
